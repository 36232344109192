import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';
import { TransitionFade } from '/src/components/animators';
import { Button } from '@material-ui/core';

import { LayoutContext } from '/src/layouts';
import { METADATA } from '/src/globals';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../components/PageWrapper';

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const LayoutComponents = (props) => {

  const layoutContext = useContext(LayoutContext);

  //////
  
  return ( <>
    
    <Helmet>
      <title>Layout Components{METADATA.titleBarPostfix}</title>
      <meta name="description" content="This page is about..." />
    </Helmet>


    <PageWrapper>

      <TransitionFade>
  
        <Typography variant="h1">
          Layout Components
        </Typography>


        <Typography variant="h2">
          Page/Layout communication
        </Typography>
        <Typography variant="body1">
          Hover over the buttons below to see how data and elements in the layout component can be communicated with. The message below the buttons is also passed down from the layout component.
        </Typography>
        <Button
          type = "button"
          variant = "contained"
          color = "secondary"
          size = "small"
          onMouseEnter = {layoutContext.shrinkHeader}
          >
          Shrink
        </Button>
        <Button
          type = "button"
          variant = "contained"
          color = "secondary"
          size = "small"
          onMouseEnter = {layoutContext.enlargeHeader}
          >
          Enlarge
        </Button>
        <Typography variant="body1">
          {layoutContext.message}<br/>
        </Typography>


        <Typography variant="h2">
          Description
        </Typography>
        <Typography variant="body1">
          Layout components are stored in the "layouts" folder and built like normal components. Unlike normal components, however, they're mounted to wrap all other components by the gatsby-plugin-layout plugin.
        </Typography>
        <Typography variant="body1">
          Because Gatsby is made up of individual static pages created at build time, page changes unmount components. A layout component included through the plugin, however, will persist wherever the layout is used.
        </Typography>
        <Typography variant="body1">
          The title animation in the header demonstrates a how a layout will stay mounted across static pages. ie. The title should only animated once (rather than again on each page).
        </Typography>
        <ul>
          <Typography variant="body1">
            <li><Link to="https://www.gatsbyjs.com/plugins/gatsby-plugin-layout/">Plugin documentation</Link></li>
          </Typography>
        </ul>
        <Typography variant="body1">
          Multiple layouts are possible: <Link to="https://www.gatsbyjs.com/plugins/gatsby-plugin-layout/#handling-multiple-layouts">Plugin Documentation</Link>
        </Typography>

      </TransitionFade>
      

      <DemoNavigation/>

    </PageWrapper>
  
  </> )

}

LayoutComponents.propTypes = propTypes;
export default LayoutComponents;
